import { NotFoundError } from '@magicdoor/errors';
import { CURRENT_UNIT_IS_UNLISTED } from '~/assets/strings';
import { toast } from '~/components/common/toaster';
import { NotOnboardedError } from '~/errors/notOnboardedError';
import { companyUnitListingGateway } from '~/gateways/companyUnitListingGateway';
import { MagicUseCase } from '../magicUseCase';

export class CreateRentalApplicationUseCase extends MagicUseCase {
  protected async runLogic(unitId?: string) {
    try {
      if (unitId) {
        await companyUnitListingGateway.getUnitListingDetail(unitId);
      }

      this.getState().initializeRentalApplication();

      let path = `/rental-applications/applicant-information`;
      if (unitId) {
        path = `${path}?unitId=${unitId}`;
      }

      this.navigate(path);
    } catch (error) {
      if (error instanceof NotFoundError || error instanceof NotOnboardedError) {
        toast(CURRENT_UNIT_IS_UNLISTED, 'error');

        const timer = setTimeout(() => {
          this.navigate('/');
          clearTimeout(timer);
        }, 3000);
      } else {
        throw error;
      }
    }
  }
}
